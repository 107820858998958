<template>
  <div class="approval">
    <div class="title">
      <p class="round"></p>
      <p>会员等级设置</p>
    </div>
    <div class="_SelectHeader">
      <div @click="handleAddOfEdit(true)">
        <img src="../../assets/images/addBtn.png" alt="" />新增等级
      </div>
      <img src="../../assets/images/delBtn.png" @click="deleteItem" />
      <img
        src="../../assets/images/editBtn.png"
        alt=""
        @click="handleAddOfEdit(false)"
      />
    </div>
    <el-table
      border
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      :data="tableData"
      style="width: 100%"
      :height="elementHeight"
      @row-click="rowDblclick"
      highlight-current-row
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
    >
      <el-table-column prop="" label="消费金额">
        <template slot-scope="scope">
          <span>{{ scope.row.useMoneyFrom }}-{{ scope.row.useMoneyTo }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="memberLevel" label="会员等级"> </el-table-column>
      <el-table-column prop="equityState" label="权益说明"> </el-table-column>
      <!-- <el-table-column prop="" label="权益会员"> </el-table-column> -->
    </el-table>
    <el-dialog
      :title="isAdd ? '新增会员' : '修改会员'"
      class="leveDialog"
      :visible.sync="showDialog"
      width="25%"
    >
      <el-form label-width="80px">
        <el-form-item label="消费金额">
          <div class="money">
            <el-input
              type="number"
              v-model="useMoneyFrom"
              @change="useMoneyFromInp"
            ></el-input>
            <span>—</span>
            <el-input
              type="number"
              v-model="useMoneyTo"
              @change="useMoneyToInp"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="会员等级">
          <el-input v-model="memberLevel"></el-input>
        </el-form-item>
        <el-form-item label="权益说明">
          <el-input type="textarea" v-model="equityState"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="reqData">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      showDialog: false,
      tableData: [],
      isAdd: true,
      dataItem: {},
      useMoneyFrom: "",
      useMoneyTo: "",
      memberLevel: "",
      equityState: "",
    };
  },
  mounted() {
    this.getElementHeight();
    this.getTableData();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 123 + 30); //70是div底部距离底部的高度
      });
    },
    getTableData() {
      get("/api/MemberLevel").then((resp) => {
        if (resp.code == 200) {
          this.dataItem = {};
          this.tableData = resp.data;
        }
      });
    },
    useMoneyFromInp(e) {
      if (e > Number(this.useMoneyTo) && this.useMoneyTo) {
        this.$message({
          type: "warning",
          message: "开始金额大于结束金额",
        });
        this.useMoneyFrom = "";
        return;
      }
      this.useMoneyFrom = e;
    },
    useMoneyToInp(e) {
      if (e < Number(this.useMoneyFrom)) {
        this.$message({
          type: "warning",
          message: "结束金额小于开始金额",
        });
        this.useMoneyTo = "";
        return;
      }
      this.useMoneyTo = e;
    },
    rowDblclick(row) {
      this.dataItem = row;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.dataItem.index == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    handleAddOfEdit(isAdd) {
      if (isAdd == false && JSON.stringify(this.dataItem) == "{}") {
        this.$message({
          type: "warning",
          message: "未选中列表",
        });
        return;
      }
      this.isAdd = isAdd;
      this.showDialog = true;
      this.useMoneyFrom = isAdd ? "" : this.dataItem.useMoneyFrom;
      this.useMoneyTo = isAdd ? "" : this.dataItem.useMoneyTo;
      this.memberLevel = isAdd ? "" : this.dataItem.memberLevel;
      this.equityState = isAdd ? "" : this.dataItem.equityState;
    },
    reqData() {
      var methods = this.isAdd ? post : put;
      methods("/api/MemberLevel", {
        id: this.isAdd ? 0 : this.dataItem.id,
        useMoneyFrom: this.useMoneyFrom,
        useMoneyTo: this.useMoneyTo,
        memberLevel: this.memberLevel,
        equityState: this.equityState,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: (this.isAdd ? "新增" : "修改") + "成功",
            });
            this.showDialog = false;
            this.getTableData();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error((this.isAdd ? "新增" : "修改") + "失败");
        });
    },
    deleteItem() {
      if (JSON.stringify(this.dataItem) == "{}") {
        this.$message({
          type: "warning",
          message: "未选中列表",
        });
        return;
      }
      this.$confirm(
        "您确认要删除" + this.dataItem.memberLevel + "这条会员等级信息?"
      )
        .then(() => {
          deleteMethod("/api/MemberLevel?Id=" + this.dataItem.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getTableData();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.approval {
  padding: 20px;
  background-color: white;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .round {
      width: 6px;
      height: 6px;
      border-radius: 50px;
      border: 2px solid #4f89ff;
      margin-right: 10px;
    }
  }
  ._SelectHeader {
    > div {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    > img {
      margin-right: 10px;
    }
  }
  .leveDialog {
    .money {
      display: flex;
      align-items: center;
      > .el-input {
        width: 100px !important;
      }
      > span {
        margin: 0 5px;
      }
    }
  }
}
</style>